import omit from 'lodash/omit';
import { NextRouter } from 'next/router';
import {
  NeugelbSearchApiResult,
  NeugelbSuggestResults,
  queryResultsApi,
  querySuggestionsApi,
} from '@cds/search-client';
import { DeviceTypes } from '@utils/MobileChecker';
import { findWidgetsToRender, WIDGET_TYPES } from '@utils/WidgetChecker';
import { GA4SearchType } from '@utils/tracking';

import { SuggestionResultType, Suggestions } from './types';

function processSuggestions(
  rawResults: NeugelbSuggestResults[],
): SuggestionResultType[] {
  const [res] = rawResults;

  return (res?.suggest.suggestions ?? []).map((suggestion) => ({
    name: suggestion.text,
    // @todo: where are completions used ? do they need a separate type?
    widgetsToRender: [],
    highlight: [],
  }));
}

function processResults(
  rawResults: Array<NeugelbSearchApiResult>,
  mobileOs: DeviceTypes,
  typoCorrection?: string,
): Suggestions | 'SEARCH_DOWN' {
  const [res] = rawResults;

  //@ts-ignore
  if (res.message?.includes('Undefined index')) {
    return 'SEARCH_DOWN';
  }

  return {
    hits: res?.search?.numTotalHits,
    completions: (res?.suggest?.suggestions ?? []).map((suggestion) => ({
      name: suggestion.text,
      // @todo: where are completions used ? do they need a separate type?
      widgetsToRender: [],
      highlight: [],
    })),
    results: (res?.search?.hits ?? []).map((hit, index) => {
      // const hitPath = new URL(hit.url).pathname.slice(0, -1);

      const { widget } = hit;
      let widgetsToRender: WIDGET_TYPES[] = [];

      // Only for the first item in the list, widgets should be rendered.
      if (index === 0 && widget) {
        const ctas = widget.ctas && widget.ctas.length > 0 ? widget.ctas : [];
        widgetsToRender = findWidgetsToRender(ctas, mobileOs);
      }

      return {
        name: hit.title,
        payload: '',
        description: hit.description,
        slug: hit.url,
        widget: hit.widget,
        widgetsToRender,
        category:
          hit.semanticDocumentInfo?.mainEntity?.breadcrumb?.itemListElement[1]
            ?.name,
        documentCategory: hit.category,
        highlight: hit.highlight,
      };
    }),
    typoCorrection,
  };
}

let fetchSuggestionTimeout: ReturnType<typeof setTimeout>;
let abortController: AbortController | undefined;

export const fetchSuggestions = ({
  query,
  searchUrl,
}: {
  query: string;
  searchUrl: string;
}): Promise<SuggestionResultType[]> =>
  new Promise((resolve, reject) => {
    if (!query.length) {
      resolve([]);
    } else {
      clearTimeout(fetchSuggestionTimeout);
      abortController?.abort();
      abortController = new AbortController();
      fetchSuggestionTimeout = setTimeout(async () => {
        try {
          const { data } = await querySuggestionsApi({
            url: searchUrl,
            signal: abortController?.signal,
            query,
          });
          resolve(processSuggestions(data));
        } catch (e: any) {
          reject(e);
        }
        abortController = undefined;
      }, 300);
    }
  });

export async function fetchResults(
  {
    query,
    searchUrl,
    limit,
    offset,
    correctTypos,
  }: {
    query: string;
    searchUrl: string;
    limit?: number;
    offset?: number;
    correctTypos: boolean;
  },
  mobileOs: DeviceTypes,
): Promise<Suggestions | 'SEARCH_DOWN'> {
  if (!query.length) {
    return {
      hits: 0,
      completions: [],
      results: [],
    };
  }

  abortController?.abort();
  abortController = new AbortController();

  const requestParams = {
    url: searchUrl,
    signal: abortController.signal,
    query,
    limit,
    offset,
  };

  const { data } = await queryResultsApi(requestParams);

  const processedResults = processResults(data, mobileOs);

  if (processedResults !== 'SEARCH_DOWN') {
    if (processedResults.hits === 0) {
      const { data: data2 } = await queryResultsApi({
        ...requestParams,
        fuzzy: true,
      });
      return processResults(data2, mobileOs);
    }

    if (correctTypos) {
      const typoCorrection = getTypoCorrection(processedResults.results);
      if (typoCorrection !== undefined) {
        const { data: data3 } = await queryResultsApi({
          ...requestParams,
          query: typoCorrection,
        });
        return processResults(data3, mobileOs, typoCorrection);
      }
    }
  }
  return processedResults;
}

function getTypoCorrection(searchResults: SuggestionResultType[]) {
  return searchResults
    .find((item) => item.highlight.length !== 0)
    ?.highlight?.find((item) => item.match(/<em>(.+?)<\/em>/))
    ?.match(/<em>(.*?)<\/em>/)![1]
    .toLowerCase();
}

export function shorten(
  string: string,
  maxLength: number,
  separator = ' ',
): string {
  if (string.length <= maxLength) return string;

  const endSentenceRegEx = /[!.?]$/g;

  let shorterDescription = string
    .substring(0, string.lastIndexOf(separator, maxLength))
    .trim();

  if (endSentenceRegEx.test(shorterDescription)) return shorterDescription;
  if (shorterDescription.endsWith(','))
    shorterDescription = shorterDescription.slice(
      0,
      shorterDescription.length - 1,
    );
  return shorterDescription.concat('...');
}
export function updateSearchParameters(
  router: NextRouter,
  args: {
    q: string;
    p: number;
    pushHistory: boolean;
    searchType?: string;
  },
): void {
  const { q, p, pushHistory, searchType } = args;
  const pathname = router.pathname;

  const parameters = {
    ...omit(router.query, ['q', 'p', 'searchType']),
    ...(q !== '' ? { q } : {}),
    ...(p !== 1 ? { p } : {}),
    ...(searchType ? { searchType } : {}),
  };

  const update = pushHistory ? router.push : router.replace;
  update({ pathname, query: parameters }, undefined, { shallow: true });
}

export function getUrlParameter(
  router: NextRouter,
  name: string,
): string | undefined {
  const param = router.query[name];
  return Array.isArray(param) ? param[0] : param;
}

export function getSearchParameters(router: NextRouter): {
  q: string;
  p: number;
  searchType: GA4SearchType;
} {
  const q = getUrlParameter(router, 'q') ?? '';
  const pStr = getUrlParameter(router, 'p');
  const pNum = pStr !== undefined ? parseInt(pStr) : NaN;
  const p = !Number.isNaN(pNum) ? pNum : 1;
  const searchTypeParam = getUrlParameter(router, 'searchType');
  const searchType = isGA4SearchType(searchTypeParam)
    ? searchTypeParam
    : 'Unknown';

  return { q, p, searchType };
}

export function isGA4SearchType(value: any): value is GA4SearchType {
  return (
    [
      'Direct entry',
      'Search suggestion',
      'Default suggestion',
      'Typo suggestion',
      'Unknown',
    ] as GA4SearchType[]
  ).includes(value);
}

export const maskIfNecessary = (url: string): string => {
  if (
    !url.includes('searchType=Search%20suggestion') &&
    !url.includes('searchType=Default%20suggestion') &&
    !url.includes('searchType=Typo%20suggestion')
  ) {
    return url.replace(/(\?|&)q=[^&]*/, '$1q=***');
  }
  return url;
};
