"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const CallToActionCategory = {
    OnlineBanking: 'online banking',
    IosApplication: 'ios application',
    IosBankingApp: 'ios banking app',
    AndroidApplication: 'android application',
    AndroidBankingApp: 'android banking app',
    CustomerService: 'customer service',
    Unspecified: 'unspecified',
    Directions: 'directions',
    Banner: 'banner',
};
