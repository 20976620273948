import React from 'react';
import Head from 'next/head';

import { ensureTrailingSlash } from '@utils/helpers/linkHelpers';
import { isGpp, isGppDE } from '@utils/pageUtils';

export interface SeoDataProps {
  title: string;
  description?: string;
  metaRobotsTag: string;
  googleSiteVerificationTag?: string;
  slug?: string;
  absoluteUrl: string;
  documentLanguage: string;
  isIndexPage: boolean;
  isSearchVisible: boolean;
  imageSrc?: string;
}

export interface OGImageData {
  url: string | undefined;
  width: number | undefined;
  height: number | undefined;
}

export const createOgImageTag = (key: string, value?: string | number) => {
  const isHTTP = key === 'url' && !key.includes('http');
  return value ? (
    <meta
      property={key ? `og:image:${key}` : 'og:image'}
      content={isHTTP ? `https:${value}` : `${value}`}
    />
  ) : (
    <></>
  );
};

export function SeoData({
  title,
  description,
  metaRobotsTag,
  googleSiteVerificationTag,
  slug,
  absoluteUrl,
  isIndexPage,
  isSearchVisible,
  imageSrc,
}: SeoDataProps) {
  let canonicalUrl = ensureTrailingSlash(absoluteUrl);
  const metaDescription = isIndexPage
    ? !isGpp
      ? 'Das Serviceportal der Commerzbank bietet rund um die Uhr Antworten und Lösungen für die wichtigsten Kundenfragen.'
      : isGppDE
        ? 'Der Commerzbank Hilfebereich bietet rund um die Uhr Antworten und Lösungen für die wichtigsten Kundenfragen.'
        : 'The Commerzbank Help Section offers answers and solutions for the most important customer inquiries around the clock.'
    : description;
  return (
    <Head>
      <meta charSet="utf-8" />
      <title>{title}</title>
      {metaDescription && (
        <>
          <meta name="description" content={metaDescription} />
          <meta
            property="og:description"
            content={description ?? metaDescription}
          />
        </>
      )}
      {!(slug === '/search') && <link rel="canonical" href={canonicalUrl} />}

      {metaRobotsTag && <meta name="robots" content={metaRobotsTag} />}
      {googleSiteVerificationTag && (
        <meta
          name="google-site-verification"
          content={googleSiteVerificationTag}
        />
      )}
      <meta property="og:title" content={title} />
      <meta property="og:type" content={'website'} />
      <meta property="og:url" content={canonicalUrl} />
      {createOgImageTag('', imageSrc)}

      {/* {navItemJsonLD && navItemJsonLD}
      {hasPaginationParameters && isNextLink && (
        //eslint-disable-next-line
        <link rel="next" href={`${prefixedSlug}/page/${nextPagePostion}/`} />
      )}
      {hasPaginationParameters && isPrevLink && (
        //eslint-disable-next-line
        <link rel="prev" href={`${prefixedSlug}/${previousPagePostion}`} />
      )} */}
      {/* <meta
        httpEquiv="delegate-ch"
        content="sec-ch-ua https://sds.commerzbank.de; sec-ch-ua-full-version-list https://sds.commerzbank.de; sec-ch-ua-full-version https://sds.commerzbank.de; sec-ch-ua-mobile https://sds.commerzbank.de; sec-ch-ua-platform https://sds.commerzbank.de; sec-ch-ua-platform-version https://sds.commerzbank.de; sec-ch-ua-arch https://sds.commerzbank.de; sec-ch-ua-bitness https://sds.commerzbank.de; sec-ch-ua-model https://sds.commerzbank.de; sec-ch-ua-wow64 https://sds.commerzbank.de;"
      ></meta> */}
      {isSearchVisible && !isIndexPage && (
        <meta name="robots" content="noindex" />
      )}
    </Head>
  );
}
