import { VariantProps, styled } from '@neui/core';
import { HStack } from '@neui/layout';
import { FC, HTMLAttributes } from 'react';
import { Typography } from '@neui/styleguide-commerzbank';

import { Link } from '@components/Link';
import { Logo } from '@components/Logo';
import { SmallLogo } from '@components/SmallLogo';
import { baseTheme, oceanTheme } from 'styling/stitches.config';
import { isGpp, isGppDE } from '@utils/pageUtils';

import { HeaderContainer } from './HeaderContainer';

export type HeaderProps = HTMLAttributes<HTMLHeadElement> & {
  title: string;
  navItems?: React.ReactNode;
} & VariantProps<typeof StyledHeaderContainer>;

const StyledHeaderContainer = styled(HeaderContainer, {
  variants: {
    isSearchActive: {
      true: {
        visibility: 'hidden',
      },
      false: {},
    },
  },
});

export const Header: FC<HeaderProps> = ({ title, navItems, ...rest }) => {
  const smallTitleDe = title.split(' ')[0];
  const smallTitleEn = title.split(' ').slice(0, 2).join(' ');

  const logoHref = !isGpp
    ? 'https://commerzbank.de'
    : isGppDE
      ? 'https://commerzbank.com/firmenkunden/'
      : 'https://commerzbank.com/corporateclients/';

  return (
    <StyledHeaderContainer {...rest}>
      <HStack
        justifyContent={'space-between'}
        alignItems="center"
        className={`${baseTheme} ${oceanTheme}`}
      >
        <HStack alignItems={'center'} spacing={24}>
          <LogoContainer>
            <Link href={logoHref}>
              <Logo />
            </Link>
          </LogoContainer>
          <SmallLogoContainer>
            <Link href={logoHref}>
              <SmallLogo />
            </Link>
          </SmallLogoContainer>
          <TextContainer>
            <StyledTypography size={4} weight={'book'}>
              {title}
            </StyledTypography>
          </TextContainer>
          <SmallTextContainer>
            <StyledTypography size={4} weight={'book'}>
              {isGppDE ? smallTitleDe : smallTitleEn}
            </StyledTypography>
          </SmallTextContainer>
        </HStack>
        {navItems && navItems}
      </HStack>
    </StyledHeaderContainer>
  );
};

const StyledTypography = styled(Typography, {
  display: 'block',
  color: 'white !important',
});

const LogoContainer = styled(HStack, {
  cursor: 'pointer',
  flex: '0 0 auto',
  display: 'none',
  height: 40,
  '@lg': {
    display: 'flex',
  },
  '& svg': {
    fill: 'white',
    display: 'block',
    height: 40,
    width: 'auto',
  },
});

const SmallLogoContainer = styled(HStack, {
  cursor: 'pointer',
  flex: '0 0 auto',
  display: 'flex',
  height: 32,
  '@lg': {
    display: 'none',
  },
  '& svg': {
    fill: 'white',
    display: 'block',
    height: 32,
    width: 'auto',
  },
});

const TextContainer = styled(HStack, {
  display: 'none',
  flex: '1 1 auto',
  maxWidth: '100%',
  justifyContent: 'flex-start',
  alignItems: 'center',
  '@sm': {
    display: 'flex',
  },
});

const SmallTextContainer = styled(HStack, {
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  justifyContent: 'flex-start',
  alignItems: 'center',
  '@sm': {
    display: 'none',
  },
});
