export const portals = [
  {
    regex: /nachhaltigkeit/,
    url: 'https://www.commerzbank.de/nachhaltigkeit',
  },
  {
    regex: /sustainability/,
    url: 'https://www.commerzbank.de/sustainability',
  },
  { regex: /konzern/, url: 'https://www.commerzbank.de/konzern/' },
  { regex: /group/, url: 'https://www.commerzbank.de/group/' },
];

export const checkNachKon = (itm: string | undefined) => {
  return itm !== undefined && portals.some((portal) => portal.regex.test(itm));
};

export const checkNachKonDE = (itm: string | undefined) => {
  return (
    itm !== undefined &&
    (portals[0].regex.test(itm) || portals[2].regex.test(itm))
  );
};

export const getNachKonMostSearchedTerms = (isNachKonDE: boolean) => {
  return isNachKonDE
    ? [
        'unternehmensführung',
        'nachhaltigkeitsstrategie',
        'diversity',
        'karriere',
        'kurse',
        'edelmetalle',
        'pressemitteilung',
      ]
    : [
        'governance',
        'sustainability strategy',
        'diversity',
        'career',
        'courses',
        'precious metals',
        'press release',
      ];
};
