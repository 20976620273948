import React, { useEffect, useState } from 'react';
import { HStack, VStack } from '@neui/layout';
import {
  Headline,
  Typography,
  communication___call,
  interaction___close,
} from '@neui/styleguide-commerzbank';
import { ICallToAction } from '@cds/search-client';

import { useTracker, WidgetName } from '@utils/snowplowTracking';
import { isCustomerService } from '@utils/WidgetChecker';
import { SnackbarToast } from '@components/neui-components/molecules/SnackbarToast';
import { baseTheme, oceanTheme } from 'styling/stitches.config';
import { IconLink } from '@components/neui-components/atoms/IconLink';
import { GA4TrackTeaserCtaClick } from '@utils/tracking';

import { WidgetActionButton } from './WidgetActionButton';
import { QrCodeWidget } from '../Widgets/QrCodeWidget';

const TelefonWidget = ({
  ctas,
  isMobile,
  widgetName,
}: {
  ctas: Array<ICallToAction>;
  isMobile: boolean;
  widgetName: WidgetName;
}): JSX.Element => {
  const { trackWidgetInteraction } = useTracker(WidgetActionButton.name);

  const customerService = ctas.find((x) => isCustomerService(x));

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsOpen(true);
    }, 500);
  }, []);

  if (!customerService?.telephone) {
    return <></>;
  }

  const url = `tel:${customerService?.telephone}`;

  const tel = customerService?.telephone;

  let formattedTel = '';

  if (tel.length === 13) {
    formattedTel =
      tel.substring(0, 3) +
      ' ' +
      tel.substring(3, 5) +
      ' ' +
      tel.substring(5, 9) +
      ' ' +
      tel.substring(9);
  }

  if (tel.length === 14) {
    formattedTel =
      tel.substring(0, 3) +
      ' ' +
      tel.substring(3, 5) +
      ' ' +
      tel.substring(5, 8) +
      ' ' +
      tel.substring(8, 12) +
      ' ' +
      tel.substring(12);
  }

  return (
    <>
      {isMobile ? (
        <WidgetActionButton
          url={url}
          label={customerService?.cta}
          icon={'communication___call'}
          widgetName={widgetName}
        />
      ) : (
        <>
          <WidgetActionButton
            icon={'communication___call'}
            url={url}
            label={customerService?.cta}
            widgetName={widgetName}
            onClick={() => setIsOpen(!isOpen)}
          />
          <SnackbarToast
            position={'right'}
            className={`${baseTheme} ${oceanTheme}`}
            open={isOpen}
          >
            <VStack spacing={8}>
              <HStack alignItems={'flex-start'} spacing={24}>
                <QrCodeWidget
                  quietZone={4}
                  ecLevel="L"
                  url={url}
                  size={96}
                  fgColor={'#E9EDF0'}
                  bgColor="#103D4B"
                  logoImage={'/icons/commerzbank-logo-light.svg'}
                  qrStyle="dots"
                  logoPadding={1}
                  widgetName={'am_telefon'}
                />

                <VStack>
                  <Headline type="h5">{formattedTel}</Headline>
                  <Typography size={7}>
                    Scannen Sie den QR-Code mit Ihrem Smartphone, um die
                    Girocard Sperrhotline anzurufen.
                  </Typography>
                </VStack>
              </HStack>
              <HStack justifyContent={'flex-end'} spacing={32}>
                <IconLink
                  href={url}
                  icon={communication___call}
                  onClick={() => {
                    trackWidgetInteraction?.(widgetName, 'cta', url, []);
                    GA4TrackTeaserCtaClick(
                      'Widget QR Code',
                      'Sperrhotline',
                      'Anrufen',
                      url,
                    );
                  }}
                  animationDirection="top"
                >
                  Anrufen
                </IconLink>
                <IconLink
                  icon={interaction___close}
                  onClick={() => setIsOpen(false)}
                  animationDirection="top"
                >
                  Schließen
                </IconLink>
              </HStack>
            </VStack>
          </SnackbarToast>
        </>
      )}
    </>
  );
};

export default TelefonWidget;
