import React from 'react';
import { uml, umlauts, umlautsReplacements } from '@cds/utils';

import {
  ChipsReducer,
  StyledLi,
  StyledUl,
} from '@components/CdsStyledComponents';
import { Chip } from '@components/neui-components/atoms/Chip';
import { useTracker } from '@utils/snowplowTracking';
import { GA4SearchType, GA4TrackInternalSearch } from '@utils/tracking';
import { useIsPuk } from '@utils/isPukBoolean';

type SuggestionChipsType = {
  mostSearchedTerms?: string[];
  executeSearch: (query: string, searchType: GA4SearchType) => Promise<void>;
};

export function SuggestionChips({
  mostSearchedTerms,
  executeSearch,
}: SuggestionChipsType): React.ReactElement {
  const { trackButtonClick } = useTracker(SuggestionChips.name);
  const { isPuk } = useIsPuk();
  const searchType: GA4SearchType = 'Default suggestion';

  return (
    <ChipsReducer>
      <StyledUl>
        {mostSearchedTerms?.map((label, index) => {
          const labelWithoutUmlaut = label.replace(uml, (c) => {
            return umlautsReplacements[umlauts.indexOf(c)];
          });
          return (
            <StyledLi key={index}>
              <Chip
                data-cy={'search-term'}
                onClick={() => {
                  trackButtonClick?.(label, 'search_term', []);
                  isPuk &&
                    GA4TrackInternalSearch(
                      labelWithoutUmlaut,
                      searchType,
                      index + 1,
                    );

                  executeSearch(label, searchType);
                }}
              >
                {label}
              </Chip>
            </StyledLi>
          );
        })}
      </StyledUl>
    </ChipsReducer>
  );
}
