"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.uml = exports.umlautsReplacements = exports.umlauts = void 0;
exports.slugify = slugify;
exports.slashify = slashify;
exports.umlauts = 'äöüß';
exports.umlautsReplacements = ['ae', 'oe', 'ue', 'ss'];
exports.uml = new RegExp(exports.umlauts.split('').join('|'), 'g');
function slugify(str, lang) {
    const language = lang !== null && lang !== void 0 ? lang : 'de';
    const a = 'àáäâãåăæçèéëêǵḧìíïîḿńǹñòóöôœøṕŕśșțùúüûǘẃẍÿź·/_,:;';
    const b = 'aaaaaaaaceeeeghiiiimnnnooooooprsstuuuuuwxyz------';
    const p = new RegExp(a.split('').join('|'), 'g');
    return str
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(exports.uml, (c) => {
        return exports.umlautsReplacements[exports.umlauts.indexOf(c)];
    }) // replace umlauts
        .replace(p, (c) => {
        return b.charAt(a.indexOf(c));
    }) // Replace special characters
        .replace(/&/g, language === 'de' ? '-und-' : '-and-') // Replace &
        .replace(/[^\w\-]+/g, '') // Remove all non-word characters
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text
}
function isExceptionalLink(href) {
    try {
        const url = new URL(href, process.env.NEXT_PUBLIC_HOST);
        // a telephone or smth else
        if (url.protocol !== 'https:' && url.protocol !== 'http:')
            return true;
    }
    catch (e) {
        return true;
    }
    // a resource
    const rgx = new RegExp('.(jpg|jpeg|png|gif|pdf|css|js|mp3|mp4|zip|rar|txt|webp|svg)$');
    if (href.match(rgx))
        return true;
    return false;
}
function slashify(url) {
    if (!url) {
        return '';
    }
    // dont add trailing slash to exceptions
    if (isExceptionalLink(url)) {
        return url;
    }
    return url.endsWith('/') ? url : url + '/';
}
