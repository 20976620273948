import { BannerTextBadgeProps } from '@components/neui-components/atoms/BannerTextBadge';

import { isGpp, isGppDE } from './pageUtils';

export const pukBadgeRules: {
  rules: RegExp[];
  attrs: BannerTextBadgeProps;
}[] = [
  {
    rules: [/\/produkte\//, /\/firmenkunden\/produkte-4\//],
    attrs: {
      title: !isGpp || isGppDE ? 'Produkt' : 'Product',
      look: 'primary',
    },
  },
  {
    rules: [
      /^.*ccb-tuc\d{2}\.cloud\.internal(:\d+)?(\/service\/?.*)?$/,
      /^.*ccb-twgi\.intranet\.commerzbank\.com(:\d+)?(\/service\/?.*)?$/,
      /commerzbank.de\/service\//,
      /\/gpp-hilfe\//,
      /^https:\/\/cds-wissen/,
      /^https:\/\/service-firmenkunden\.commerzbank\.(de|com)\/.*$/,
      /^https:\/\/service-corporates\.commerzbank\.(de|com)\/.*$/,
      /localhost(:\d+)?\/.*$/,
    ],
    attrs: {
      title: 'FAQ',
      look: 'highlight',
    },
  },
  // {
  //   rules: [/^https:\/\/filialsuche/, /\/filialen\//],
  //   attrs: {
  //     title: 'Filiale',
  //     look: 'optional',
  //   },
  // },
  // {
  //   rules: [/\/blog\//, /\/wissen\//, /\/ratgeber\//, /\/themen\//],
  //   attrs: {
  //     title: 'Artikel',
  //     look: 'secondary-4',
  //   },
  // },
  // {
  //   rules: [/\/hauptnavigation\/presse\//],
  //   attrs: {
  //     title: 'Presse',
  //     look: 'secondary-2',
  //   },
  // },
];

export const nachKonBadgeRules: {
  rules: RegExp[];
  attrs: BannerTextBadgeProps;
}[] = [
  {
    rules: [/\/was-uns-bewegt\//],
    attrs: {
      title: 'Magazin',
      look: 'secondary-1',
    },
  },
  {
    rules: [/\/what-drives-us\//],
    attrs: {
      title: 'Magazine',
      look: 'secondary-1',
    },
  },
  {
    rules: [/\/nachhaltigkeit\//],
    attrs: {
      title: 'Nachhaltigkeit',
      look: 'secondary-2',
    },
  },
  {
    rules: [/\/sustainability\//],
    attrs: {
      title: 'Sustainability',
      look: 'secondary-2',
    },
  },
  {
    rules: [/\/newsroom\//],
    attrs: {
      title: 'News', //same DE and EN
      look: 'secondary-3',
    },
  },
  {
    rules: [/\/kursinformationen\//],
    attrs: {
      title: 'Devisenkurse & Edelmetalle',
      look: 'secondary-4',
    },
  },
  {
    rules: [/\/price-information\//],
    attrs: {
      title: 'FX Rates & Precious metals',
      look: 'secondary-4',
    },
  },
  {
    rules: [/\/karriere\//],
    attrs: {
      title: 'Karriere',
      look: 'secondary-5',
    },
  },
  {
    rules: [/\/careers\//],
    attrs: {
      title: 'Careeers',
      look: 'secondary-5',
    },
  },
  {
    rules: [/\/research\//],
    attrs: {
      title: 'Research', //same DE and EN
      look: 'secondary-6',
    },
  },
];

export function defaultAttrs(isNachKonDe: boolean): BannerTextBadgeProps {
  return {
    title:
      !isGpp || isGppDE || (!isGpp && isNachKonDe)
        ? 'Wissenwertes'
        : 'Knowledge',
    look: 'supplementary',
  };
}

export function nachKonMostSearchedTerms(
  isNachKon: boolean,
  isNachKonDe: boolean,
) {
  return isNachKon
    ? isNachKonDe
      ? [
          'unternehmensführung',
          'nachhaltigkeitsstrategie',
          'diversity',
          'karriere',
          'kurse',
          'edelmetalle',
          'pressemitteilung',
        ]
      : [
          'governance',
          'sustainability strategy',
          'diversity',
          'career',
          'courses',
          'precious metals',
          'press release',
        ]
    : undefined;
}
